.linearProgressDescription{
    margin-bottom: 12px;
}
.linearProgressDescription__left{
    font-size: 18px;
    font-weight: 700;
    line-height: 20.68px;
    letter-spacing: 0.5px;
}

.linearProgressDescription__num{
    font-size: 18px;
    font-weight: 700;
    line-height: 20.68px;
    letter-spacing: 0.1em;
}

.linearProgressDescription__num__all{
    color: #8A3DFF;
}