@import "styles/reset.scss";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

.wrapper{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: #1D162B;
    z-index: 1;
}

.container {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}

.main{
    padding: 32px 120px 80px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.font{
    font-family: 'Ubuntu', sans-serif;
}

.circle {
    position: absolute;
    flex-shrink: 0;
    border-radius: 50%;
    z-index: -1000;
}

.color1{
    background: #6A06FE;
    filter: blur(250.01722717285156px);
    top: 0%;
    left: -10%;
    width: 40vw;
    height: 60vh;
}

.color2{
    background: #610681;
    filter: blur(180.01722717285156px);
    top: 20%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: 45vw;
    height: 70vh;
}

.color3{
    background: #ED065F;
    filter: blur(200.01722717285156px);
    bottom: 20%;
    left: 10%;
    width: 35vw;
    height: 60vh; 
    z-index: -1000;
}

.header{
    margin: 0 0 32px 0;
}

.headerIcon{
    margin: 0 12px 0 0;
}

.headerTitle{
    font-size: 20px;
    font-weight: 700;
    line-height: 22.98px;
    letter-spacing: 0.2em;
}