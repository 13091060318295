* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height:100vh;
  padding:0;
  margin:0;
  position: relative;
  overflow:hidden;
  font-family: 'DM Sans', sans-serif;
  font-size:12px;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
  font-weight: 400;
  letter-spacing: 0.3px;
  a {
    color: var(--white);
    text-decoration: none;
  }
  ul{
    padding:0;
    margin:0;
    list-style:none;
    li{
      display:block;
    }
  }
  p {
    margin: 0;
    color: white;
  }
  h1, h2, h3, h4 , h5, h6{
    margin: 0;
  }
  svg{
    display: block;
  }
}

input,
button,
textarea,
select {
  margin: 0;
  font: inherit;
}
