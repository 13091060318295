.card{
    max-width: 100%;
    min-height: calc(100% - 56px);
    background: #FFFFFF12 7%;
    padding: 40px 40px 32px;
    border-radius: 20px;
}

.photoContainer{
    position: relative;
}

.statusLabel{
    position: absolute;
    top: 3%;
    left: 54%;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
}

@media only screen and (max-width: 400px) {
    .photo {
        width: 280px;
        height: 449px;
    }
}

.cardTitle{
    font-size: 56px;
    font-weight: 700;
    line-height: 64.34px;
    margin-bottom: 24px;
}

.cardDescription{
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 32px;
    opacity: 80%;
}

.linearProgress{
    margin: 0 0 33px 0;
}

.childInfo{
    margin: 0 0 40px 0;
    font-size: 16px;
    line-height: 18.38px;
}
.childInfo__row{
    margin: 0 0 16px 0;
}

.childInfo__title{
    font-weight: 700;
    margin: 0 8px 0 0;
}

.childInfo__desc{
    opacity: 80%;
}

.buttonHelp{
    margin: 0 20px 0 0;
}

.firstSquare{
    margin: 0 0 31px 0;
}

.issueTitle{
    font-size: 18px;
    line-height: 20.68px;
    margin-bottom: 20px;
    font-weight: 700;
    margin-top: 32px;
}

.miniCard{
    background: #FFFFFF12 7%;
    padding: 24px;
    border-radius: 20px;
}

.issueCard{
    margin: 0 32px 0 0;
}

.issueIcon{
    margin-bottom: 16px;
}

.descSmall{
    font-size: 16px;
    line-height: 18.38px;
    margin-bottom: 4px;
    font-weight: 700;
}

.prognosisTitle{
    margin-bottom: 8px;
}

.textAlign{
    text-align: center;
}
.problemDesc{
    line-height: 22px;
}

.issuesBlock{
    margin-bottom: 32px;
}

.downloadTitle{
    font-size: 16px;
    margin-bottom: 20px;
}

.mediumCard{
    max-width: 800px;
}

.qrImg{
    margin-right: 24px;
}

.googleImg{
    margin-bottom: 24px;
}

button{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767.98px) {
    .main{
        padding: 10px 10px;
    }

    .share-view{
        display: none;
    }
    
    .photo{
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        margin-right: 32px;
        position: relative;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991.8px) {
    .main{
        padding: 60px 60px;
    }

    .share-view{
        display: none;
    }

    .photo{
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        margin-right: 32px;
        position: relative;
    }

    .card{
        padding: 40px 32px 81px 32px;
    }

    .cardTitle{
        font-size: 22px;
    }

    .cardDescription{
        font-size: 14px;
    }
    
    .linearProgressDescription__num{
        font-size: 14px;
    }

    .mobile{
        display: none;
    }

    .cardText{
        flex-direction: column-reverse;
    }

    .issueCard{
        margin: 0;
    }

    .qrImg{
        margin-right: 0px;
        margin-bottom: 21px;
    }
}

@media (min-width: 900px)  and (max-width: 991.98px) {
    .miniCard {
        padding: 10px;
        max-width: 95%;
    }
}


@media (min-width: 540px)  and (max-width: 991.98px) {
    .qrImg{
        margin-right: 10px !important;
        margin-top: 20px !important;
    }
}

@media (min-width: 600px)  and (max-width: 750px) {
    .miniCard {
        padding: 10px;
        max-width: 95%;
    }
}

@media (min-width: 600px)  and (max-width: 991.98px) {
    .MuiGrid-grid-md-7{
        margin-left: 10px !important;
        max-width: 40% !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .mobile{
        display: none;
    }

    .photo{
        width: 276px;
        height: 443px;
        object-fit: cover;
        border-radius: 16px;
        margin-right: 32px;
        position: relative;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { 
    .main{
        padding: 80px 80px;
        width: 100%;
        overflow: hidden;
    }
} */
